@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400&family=Open+Sans:wght@400;600&display=swap');

* {
  /* font-family: 'Poppins', sans-serif; */
  font-family: 'Nunito', sans-serif;
}

.f-Sans-bold {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 600;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
}

.m-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.m-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mt5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-30 {
  margin-left: 30px;
}

.t-center {
  text-align: center;
}

.f-right {
  float: right;
}

.grey-b {
  color: #5e646b;
  font-weight: 600;
}

.light-grey {
  color: #8d8d8d;
}

.f-10 {
  font-size: 10px;
}

.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px;
}

.f-16 {
  font-size: 16px;
}

.card-head {
  font-size: 20px;
  font-weight: 800;
}

.app-blue-bg {
  background: #1A68C5;
}

.app-orange-bg {
  background: #F89728;
}

.app-blue-txt {
  color: #1A68C5;
}

.app-orange-txt {
  color: #F89728;
}

.lightBlue {
  color: #0075FF;
}

.pinkRed {
 color: #fb7d7f;
}

.white {
  color: white;
}

.cp {
  cursor: pointer;
}

.img-radius {
  border-radius: 50%;
  border: 2px solid #fff;
  -webkit-box-shadow: 0 5px 10px 0 rgba(43, 43, 43, 0.2);
  box-shadow: 0 5px 10px 0 rgba(43, 43, 43, 0.2);
}

.bold {
  font-weight: 800;
}

.invisible-field {
  border: none;
  outline: none;
  width: 130px;
}

.invisible-field:focus {
  border: none;
  outline: none;
}

.verifyCodeInput {
    width: 55px;
    height: 55px;
    border-radius: 5px;
    font-size: 35px;
    padding: 15px;
    border: 1px solid #CCCCCC;
}

.greyBackground {
  position: absolute;
  background: #f9f9f9;
  width: 100%;
  height: 340px;
  top: 233px;
  right: 0px;
  border-radius: 0px 0px 5px 5px;
  border-top: 1px solid #CCCCCC;
}

.divider {
  width: 497px;
  position: relative;
  border-top: 1px solid lightgrey;
  right: 40px;
}

.table-heading {
  padding: 20px 20px 5px 15px;
  font-weight: 800;
}

.ant-table-column-title {
  font-family: 'Open Sans', sans-serif !important;
}

.table-striped-rows tr:nth-child(2n) td {
  background-color: #fff;
}

.table-striped-rows tr:nth-child(2n-1) td {
  background-color: #E1EDFB;
}

.table-striped-rows thead {
  background-color: #f1f1f1;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7); /* Semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

 

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3); /* Light border color */
  border-top: 4px solid #3498db; /* Loading spinner color */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  z-index: 1000;
}

 

@keyframes spin {

  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }

}

.paymentMethod {
  padding: 20px;
  background: #D9D9D9;
  padding: 40px 15px;
  height: 175px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
}

.selectedMethod {
  background: #f2f8ff;
  border: 1px solid #0075FF;
}

/* .theCover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 0, 0, 0.5);
  z-index: -1; 
} */


/*********************** Theme CSS ***********************/

