:where(.css-dev-only-do-not-override-1n7nwfa).ant-table-wrapper .ant-table-thead >tr>th, :where(.css-dev-only-do-not-override-1n7nwfa).ant-table-wrapper .ant-table-thead >tr>td {
    background: #fff;
}

/* :where(.css-dev-only-do-not-override-1n7nwfa).ant-table-wrapper .ant-table:not(.ant-table-bordered) .ant-table-tbody >tr:first-child>td, :where(.css-dev-only-do-not-override-1n7nwfa).ant-table-wrapper .ant-table:not(.ant-table-bordered) .ant-table-tbody >tr.ant-table-measure-row+tr>td {
    background: #E1EDFB;
} */

:where(.css-dev-only-do-not-override-1n7nwfa).ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before, :where(.css-dev-only-do-not-override-1n7nwfa).ant-table-wrapper .ant-table-thead >tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0;
}

.card {
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    box-shadow: 0 0 5px 0 rgba(43, 43, 43, 0.1), 0 11px 6px -7px rgba(43, 43, 43, 0.1); 
    padding: 20px;
}

.calendar {
    padding: 15px;
}

.custom-select {
    padding-right: 30px; /* Increase or decrease the value to adjust the position */
    background-image: url('./../../assets/images/down-arrow.png'); /* Optional: Replace with your custom arrow image */
    background-repeat: no-repeat;
    background-position: right center;
    background-position-x: 95%;
    background-size: 25px 25px;
    height: 50px;
  }


.custom-dropdown-arrow::after {
    content: url('./../../assets/images/down-arrow.png');
    font-size: 10px; 
    border-top: 0;
    top: 5px;
    left: 7px;
    position: relative;
}

.table-transaction tr:nth-child(2n) td {
    background-color: #fff;
    cursor: pointer;
}

.table-transaction tr:nth-child(2n-1) td {
    background-color: #E1EDFB;
    cursor: pointer;
}

.table-transaction thead {
    background-color: #f1f1f1;
}