.sidenav-list {
    list-style: none;
    margin-top: 15px; 
    color: #B7BFCC; 
    padding: 0;
}

.sidenav-list li:hover {
  color: white;
  cursor: pointer;
}

.selected-tab {
    background: #1E2531;
    border-left: 2px solid #43A4F5;
    cursor: pointer;
}

a {
    color: unset;
}

a:hover {
    text-decoration: none;
    color: unset;
}

.head {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.goBehind {
    /* transform: translateX(-250px); */
    width: 0px !important;
    transition: all 0.5s ease !important;
    /* position: fixed !important; */
}

.goFront {
    width: 250px !important;
    transform: translateX(0px);
    transition: all 0.5s ease !important;
    /* animation: toggler2 0.5s ease forwards !important; */
}

@keyframes toggler1 {
    0% {
       /* transform: translateX(-250px); */
    }

    100% {
        display: none;
    }
}

@keyframes toggler2 {
    0% {
       /* transform: translateX(0px); */
    }

    100% {
        display: block;
    }
}

