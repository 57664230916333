.inputfield {
  width: 200px;
}

.button{
  background-color: rgb(84, 84, 248);
  color: #FFFFFF;
}

/* The alert message box */
.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.error {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}

.success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}

.warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}
.primary {
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe;
}

.secondary {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8;
}

.info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}
/* The close button */
.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

//---- Layout css ----//

.element-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.element-space-between{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-main-div{
  display: grid;
  grid-template-columns: 250px 16fr;
  height: 70px;
  // background-color:#263644;
}

.trufit-heading-div{
  text-align: center;
  width: 100%;
  margin-left: 2rem;
}

.trufit-header-heading{
  color: #FFF;
  font-family: 'Nunito';
font-size: 22px;
}

.profile-name{
  font-family: 'Nunito';
font-size: 14px;

}

.ant-layout .ant-layout-sider {
width: 250px;  
background-color: #263644;
}


.ant-layout-sider {
  width: 250px !important;
  flex: 0 0 250px !important;
  max-width: 250px !important;
  min-width: 250px !important;
  height: 100%;
}

.ant-layout .ant-layout-sider-children .ant-menu.ant-menu-inline  {
  width: 250px !important;
  height: 100%;
  background-color: #263644;
}

.ant-menu-item{
  height: 40px !important;
  padding: 24px;
  font-family: 'Nunito';
}

.ant-menu-item-selected{
  background-color: #1E2531 !important;
  margin: 0 !important;
  width: 100% !important;
  border-radius: 0px !important;
  border-left: 3px solid #43A4F5;
}

// .ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
//   background: #1677ff;
// }

.ant-layout-content {
  height: 100vh;
  overflow-y: auto;
}

.ant-picker-clear {
  font-size: 20px;
}

.ant-table-cell {
  background: white;
}

.ant-message {
  z-index: 10000;
}

.btn-close {
width: 0px;
background: transparent;
border: none;
}

.btn-close:after {
  content: url('./../../assets/images/cross.svg'); /* Use the Unicode or custom icon you prefer */
  font-size: 10px; /* Adjust the font size as needed */
  border-top: 0;
  top: 5px;
  left: -5px;
  position: relative;
}

.tag-item {
  background-color: #d4d5d6;
  display: inline-block;
  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
}

.tag-item > .button {
  background-color: white;
  color: black;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}


.otp-input {
  display: flex;
  justify-content: space-around;
}

.otp-input > input {
  width: 60px !important;
  height: 60px !important;
  text-align: center;
  margin-right: 20px;
  background: #ffffff;
  box-shadow: 4px 0px 30px rgba(0, 0, 0, 0.03), 0px 4px 30px rgba(0, 0, 0, 0.03),
    2px -1px 2px #effaff, 1px 1px 4px rgba(71, 113, 134, 0.15);
  border-radius: 10px;
  border-style: none;
  font-family: "poppins-semibold";
  font-size: 30px;
  color: #007cba;
  margin: 0 !important;
}

.forgot-pas-desc {
  font-family: "Poppins";
  font-size: 14px;
  color: #5fa0c0;
  text-align: right;
  cursor: pointer;
}

.no-accout {
  font-family: "Poppins";
  font-size: 14px !important;
  color: #526b78;
}

.yellow-underline {
  color: #f79a28;
  text-decoration: underline;
  cursor: pointer;
}

.login-des {
  font-family: "Poppins";
  font-size: 18px;
  line-height: 30px;
  color: #526b78;
}

.forgot-heading {
  font-family: "'Nunito', sans-serif";
  font-size: 34px;
  line-height: 51px;
  color: #0a364b;
}

.login-input {
  background: #ffffff;
  box-shadow: 4px 0px 30px rgba(0, 0, 0, 0.03), 0px 4px 30px rgba(0, 0, 0, 0.03),
    2px -1px 2px #effaff, 1px 1px 4px rgba(71, 113, 134, 0.15);
  border-radius: 7px;
  width: 400px;
  height: 50px;
  border-style: none;
}

.form-modal {
  width: max-content;

  .ant-modal-close {
    display: none;
  }
  .ant-modal-close-x {
    display: none;
  }

  .ant-modal-footer {
    display: none;
  }
}

.authButton {
  background: #0069d9;
  box-shadow: 4px 0px 30px rgba(0, 0, 0, 0.03), 0px 4px 30px rgba(0, 0, 0, 0.03),
    2px -1px 2px #effaff, 1px 1px 4px rgba(71, 113, 134, 0.15);
  border-radius: 10px;
  width: 400px;
  height: 50px;
  border-style: none;
  border-radius: 7px;
  font-family: "poppins-semibold";
  font-size: 16px;
  line-height: 33px;
}