/* :where(.css-dev-only-do-not-override-1n7nwfa).ant-table-wrapper .ant-table-thead >tr>th, :where(.css-dev-only-do-not-override-1n7nwfa).ant-table-wrapper .ant-table-thead >tr>td {
    background: #fff;
    border: 1px solid #E5E5E5;
    border-radius: 0;
    border-right: none;
    font-family: 'Open Sans', sans-serif !important;
} */


/* :where(.css-dev-only-do-not-override-1n7nwfa).ant-table-wrapper .ant-table:not(.ant-table-bordered) .ant-table-tbody >tr:first-child>td, :where(.css-dev-only-do-not-override-1n7nwfa).ant-table-wrapper .ant-table:not(.ant-table-bordered) .ant-table-tbody >tr.ant-table-measure-row+tr>td {
    background: #E1EDFB;
    border-right: 1px solid white;
    font-family: 'Open Sans', sans-serif !important;
} */

:where(.css-dev-only-do-not-override-1n7nwfa).ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before, :where(.css-dev-only-do-not-override-1n7nwfa).ant-table-wrapper .ant-table-thead >tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0;
    /* right: 1em;
    content: "\2191"; */
}

/* :where(.css-dev-only-do-not-override-1n7nwfa).ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before, :where(.css-dev-only-do-not-override-1n7nwfa).ant-table-wrapper .ant-table-thead >tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::after {
    content: "\2193";
    right: 1em;
} */

.card {
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    box-shadow: 0 0 5px 0 rgba(43, 43, 43, 0.1), 0 11px 6px -7px rgba(43, 43, 43, 0.1); 
    padding: 20px;
}

:where(.css-dev-only-do-not-override-1n7nwfa).ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:first-child {
    border-radius: 0;
 }

 .table-striped .ant-table-container table>thead>tr:first-child >*:last-child { 
    border-radius: 0;
    text-align: center;
    width: 250px;
 }

 .table-heading {
    padding: 20px 20px 5px 15px;
    font-weight: 400;
    font-size: 16px;
  }

  .mt-30 {
    margin-top: 30px;
  }

  .ml-10 {
    margin-left: 10px;
  }

 .paginate-dropdown {
    height: 50px;
    border-bottom: 1px solid lightgrey;;
    border-right: none;
    border-left: none;
    border-top: none;
    border-radius: 0;
  }

  .btn-circle {
    width: 30px !important;
    height: 30px;
    text-align: center;
    padding: 6px 0 !important;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px !important;
    border: none;
  }

  .f-Sans-bold {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 600; 
  }

  .btn-circle:hover {
    opacity: 0.7;
  }

  .cyan {
    background: #00bcd4;
  }

  .pink {
    background: #FF5370;
  }

  .green {
    background: #2cd8b5;
  }


  .t-head-bordered  tr > th {
    background: #fff;
    border: 1px solid #E5E5E5;
    border-radius: 0;
    border-right: none;
    font-family: 'Open Sans', sans-serif !important;
  }

  .ant-table-column-title {
    font-family: 'Open Sans', sans-serif !important;
  }

.table-striped-rows tr:nth-child(2n) td {
    background-color: #fff;
}

.table-striped-rows tr:nth-child(2n-1) td {
    background-color: #E1EDFB;
}

.table-striped-rows thead {
    background-color: #f1f1f1;
}

